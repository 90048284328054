import { useState, React } from "react";
import { dayBookings } from "../../../api";
import slotList from "../utils/slotList";
import BookingWidget from "./BookingWidget";
// import getBookings from "../utils/getBookings"

const Schedule = ({ bookDate, updateBookings }) => {
  const [daySlots, setDaySlots] = useState(null);
  const [date, setDate] = useState(bookDate);

  // let buttons = [];
  // const buttonArray = [];
  const courts = JSON.parse(sessionStorage.getItem("courts"));
  // const profile = JSON.parse(sessionStorage.getItem("profile"));

  const getDaySlots = async () => {
    try {
      const response = await dayBookings(date);
      const bookings = await response.data.result;
      setDaySlots(slotList(await bookings));
    } catch (err) {
      console.log(err);
    }
  };

const getBookings = () =>{
 let result = []
  for (let i = 0; i < courts.length; i++) {
    const slots = daySlots[i];
    result.push(<div className="bmc-slot-block" key = {courts[i].label}>

    <br />
    {slots.map((slot)=> 
    <div className="bmc-slot" key={slot.name}>
      <BookingWidget slot = {slot}
      updateBookings={updateBookings} />
    </div>
    )
    }
    </div> 
     )    
  }
  return daySlots? result : null
}

  //check for date change
  if (date !== bookDate) {
    setDate(bookDate);
    setDaySlots(null);
  }

  //initialise
  if (!daySlots) {
    getDaySlots();
  } 

  return <div>{daySlots? getBookings() : null}</div>;
};

export default Schedule;
