import { useState, React } from "react";
import { userBookings, updateSchedule } from "../../../api";
import MyBookingWidget from "./MyBookingWidget";

const MyBookings = () => {
  const [myBookings, setMyBookings] = useState(null);
  const [flagsDelete, setflagsDelete] = useState([]);
  const [flags, setFlags] = useState(false);

  const profile = JSON.parse(sessionStorage.getItem("profile"));

  const buttons = [];

  const getMyBookings = async () => {
    try {
      const response = await userBookings(profile._id);
      const bookings = await response.data.result;
      setMyBookings(await bookings);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFlag = (e, slot) => {
    const flagInfo = slot._id;
    const flaggedDelete = flagsDelete.indexOf(flagInfo) >= 0;

    const handleRevertMine = () => {
      const flagIndex = flagsDelete.indexOf(flagInfo);
      flagsDelete.splice(flagIndex, 1);
      e.target.className = "btn-my-booking-widget ";
    };
    const handleFlagDelete = () => {
      flagsDelete.push(flagInfo);
      e.target.className = "btn-my-booking-widget widget-flagged";
    };
    if (flaggedDelete) {
      handleRevertMine();
    } else {
      handleFlagDelete();
    }
    setFlags(flagsDelete.length > 0);
  };

    const btnClass = () => {
  return  flags?"btn-submit btn-active":"btn-submit btn-inactive"
  }

  const handleSubmit = async () => {
    const drop = [];

    flagsDelete.forEach((f) => {
      const newDeletion = {
        id: f,
      };
      drop.push(newDeletion);
    });

    const submissions = {
      additions: [],
      deletions: drop,
    };
    const complete = async () => {
      await updateSchedule(submissions);
      setflagsDelete([]);
      setMyBookings(null);
    };

    return await complete();
  };

  const getButtons = () => {
    myBookings.forEach((b) => {
      const button = <MyBookingWidget slot={b} handleFlag={handleFlag} key={b} />;
      buttons.push(button);
    });
  };

  //initialise
  if (!myBookings) {
    getMyBookings();
  } else {
    getButtons();
  }

  return (
    <div>
      <button
        className={btnClass()} 
        disabled={!flags}
        onClick={handleSubmit}
      >
        Delete selected bookings
      </button>
      <div>{myBookings ? buttons : null}</div>
    </div>
  );
};
export default MyBookings;
