
const toMyDate = (d) => {

    const month = d.substring(3, 5)
    const day = d.substring(0, 2)
    const year = d.substring(6)

    const result = year +"-" + month  +"-" + day

    return (result)
}
export default toMyDate 