import {React, useState} from "react"
import * as api from '../../api';
import "./styles.css"


const Documents = () =>
{ 
    console.log("test");
return (
    <div>
    <header className="doc-header">
        
    </header>
    <main
    className="doc-main"></main>
</div>)
}

export default Documents