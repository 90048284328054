import React from "react";

const Recur = ({ recurInt, recurCount, recurIntChange, recurCountChange }) => {
  const handleRecurInt = async (e) => {
    const newInt = e.target.value;
    recurIntChange(newInt);
  };
  const handleRecurCount = async (e) => {
    const newCount = e.target.value;
    recurCountChange(newCount);
  };

  return (
    <div className="recur-container">
      <label htmlFor="interval">
        Interval (days){" "}
      </label>
      <input
        type="number"
        id="interval"
        style={{ width: "3rem" }}
        onChange={handleRecurInt}
        value={recurInt}
      />
      <label htmlFor="count">
        Count{" "}
      </label>
      <input
        type="number"
        id="count"
        style={{ width: "3rem" }}
        onChange={handleRecurCount}
        value={recurCount}
      />
    </div>
  );
};
export default Recur;
