import React from 'react'


const BookingWidget = ({slot, updateBookings}) => {

  const me = JSON.parse(sessionStorage.getItem("profile"))._id
  const courts = JSON.parse(sessionStorage.getItem("courts"))
  const courtName=courts[slot.court].label

const ownerid = slot.name.split(",")[2]
const mine = me===ownerid



    const handleFlag = (e) => {
        updateBookings(e, slot)
      };

      const btnClass = () =>{
        return(
        !slot.booked?"btn-action btn-action-free":
        mine?"btn-action btn-action-mine":"btn-action btn-action-booked"
        )
    }
      const btnLabel = () =>{
        return(
        slot.booked?mine?"Booked for you":slot.bookingOwner:courtName + ": free"
        )
    }

return <div className='bmc-booking-widget'>
      <p className="bmc-slot-time">{slot.time}</p>      
      <button className={btnClass()} onClick={!slot.booked||mine?handleFlag:null}>{btnLabel()}</button>
    </div>

}
export default BookingWidget