import TextFieldGroup from "../../Common/TextFieldGroup";
import { Button } from "rsuite";
import { register, logIn } from "../../../api";
import { useState } from "react";
import ModalHeader from "rsuite/esm/Modal/ModalHeader";
import ModalBody from "rsuite/esm/Modal/ModalBody";
import { useNavigate } from "react-router-dom";

const LogReg = (args) => {
  const Navigate = useNavigate();
  const isReg = args.isReg;

  const initialState = {
    name: "",
    email: "",
    password: "",
    password2: "",
  };
  //const initialState = { name: "", email: "", password: "", password2: "" };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const header = () => {
    return isReg ? "New registration" : "Log into your account";
  };
  const formContent = () => {
    return (
      <div>
        <TextFieldGroup
          hidden={!isReg}
          placeholder='Name'
          name='name'
          type='text'
          value={formData.name}
          onChange={handleChange}
          error={errors.name ? errors.name : errors.message}
        />
        <br />
        <TextFieldGroup
          placeholder='email address'
          name='email'
          type='email'
          value={formData.email}
          onChange={handleChange}
          error={errors.email}
        />
        <br />
        <TextFieldGroup
          placeholder='password'
          name='password'
          type='password'
          value={formData.password}
          onChange={handleChange}
          error={errors.password}
        />
        <br />
        <TextFieldGroup
          hidden={!isReg}
          placeholder='confirm password'
          name='password2'
          type='password'
          value={formData.password2}
          onChange={handleChange}
          error={errors.password2}
        />
      </div>
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent screen reload
    setLoading(true);
    try {
      if (isReg) {
        sessionStorage.setItem(
          "profile",
          JSON.stringify((await register(formData)).data.result)
        );
      } else {
        sessionStorage.setItem(
          "profile",
          JSON.stringify((await logIn(formData)).data.result)
        );
      }
      sessionStorage.setItem(
        "token",
        JSON.stringify((await logIn(formData)).data.token)
      );

      // sessionStorage.setItem("courts", JSON.stringify(await courts));
      // sessionStorage.setItem("slots", JSON.stringify(await slots));

      //get account details

      Navigate("/");
      Navigate(0);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setErrors(error.response.data);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <ModalHeader>{header()}</ModalHeader>
      <ModalBody>{formContent()}</ModalBody>
      <Button onClick={handleSubmit} loading={loading}>
        {isReg ? "Register" : "Login"}
      </Button>
    </div>
  );
};
export default LogReg;
