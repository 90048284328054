import TextFieldGroup from "../../Common/TextFieldGroup";
import { Button } from "rsuite";
import { logIn } from "../../../api";
import { useState } from "react";
import ModalHeader from "rsuite/esm/Modal/ModalHeader";
import ModalBody from "rsuite/esm/Modal/ModalBody";
import { useNavigate } from "react-router-dom";


const ExpiredToken = () => {
  const navigate = useNavigate();
  const initialState = { name: "", email: "", password: "", password2: "" };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const header =
    "Your session has expired. You will need to log into your account again";
  const formContent = () => {
    return (
      <div>
        <TextFieldGroup
          placeholder="email address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={errors.email}
        />
        <br />
        <TextFieldGroup
          placeholder="password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          error={errors.password}
        />
      </div>
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent screen reload
    setLoading(true);
    try {
      sessionStorage.setItem(
        "profile",
        JSON.stringify((await logIn(formData)).data.result)
      );
      sessionStorage.setItem(
        "token",
        JSON.stringify((await logIn(formData)).data.token)
      );
      //get account details

      navigate(0);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setErrors(error.response.data);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{formContent()}</ModalBody>
        <Button onClick={handleSubmit} loading={loading}>
          {"Login"}
        </Button>
    </div>
  );
};
export default ExpiredToken;
