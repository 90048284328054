import * as api from "../../../api";
import toMyDate from "../../../utils/toMyDate";

const submitDayBookings = async (
  date,
  additions,
  deletions,
  recurInt,
  recurCount
) => {
  ////Make additions
  const add = [];
  const drop = [];
  for (let index = 0; index < recurCount; index++) {
    let d = new Date(date);
    d.setDate(d.getDate() + recurInt * index);
    additions.forEach((a) => {
      const courtId = a.split(",")[1];
      const slotId = a.split(",")[0];
      const ownerId = JSON.parse(sessionStorage.getItem("profile"))._id;
      const bookingType = "65367fd30e788cd9d5261fa6";
      const newBooking = {
        owner: ownerId,
        bookingSlot: slotId,
        bookingType: bookingType,
        bookingCourt: courtId,
        date: toMyDate(d.toLocaleDateString()),
      };
      add.push(newBooking);
    });
  }

  deletions.forEach((d) => {
    const bookingId = d.split(",")[3];
    const newDeletion = {
      id: bookingId,
    };
    drop.push(newDeletion);
  });

  const submissions = {
    additions: add,
    deletions: drop,
  };

  const result = await api.updateSchedule(submissions);

  return result;
};
export default submitDayBookings;
