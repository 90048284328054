import React from "react";

import NavBar from "./components/NavZone/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Booking from "./components/Booking/Booking"
import Documents from "./components/Documents/Documents";
import Footer from "./components/Footer/Footer";
import Auth from "./components/Auth/Auth";
import "./styles/styles.css"

const App = () => {
  return (
    <div className="">
      <header className="bmc-header">
        <NavBar />
      </header>
      <main className="">
        <Routes>
          <Route path="/pwchange/:token" element={<Auth />} />
          <Route path="/bookings" element={<Booking 
          source={"schedule"} />} />
          <Route path="/bookings/user" element={<Booking
          source={"user"} />} />
          <Route path="/bookings/recur" element={<Booking
          source={"recur"} />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/logIn" element={<Auth />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
      <footer className="bmc-footer">
        <Footer />
      </footer>
    </div>
  );

};
export default App;
