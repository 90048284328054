import React from "react";
import toMyDate from "../../../utils/toMyDate";
//import submitDayBookings from "../utils/submitDayBookings";

const Calendar = ({ date, changeDate, daysAhead, submitChanges }) => {


  const minDate = new Date();
  const maxDate = new Date();

  maxDate.setDate(maxDate.getDate() + daysAhead)

  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    changeDate(newDate);
  };

  const handleSubmit = async () => {
     const complete = async () => {
      await submitChanges()
    }; 
    return await complete();
  };

  return (
    <div>
      <div className='row d-block'>
        <label htmlFor='#Calendar' className='px-2 mx-3'>
          Select a date:
        </label>
        <input
          type='date'
          onChange={handleDateChange}
          value={date}
          min={toMyDate(minDate.toLocaleDateString())}
          max={toMyDate(maxDate.toLocaleDateString())}
        ></input>

      </div>
    </div>
  );
};
export default Calendar;
