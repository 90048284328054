import React from "react";


const MyBookingWidget = ({
  slot, handleFlag
}) => {

  const btnClass = () => {
  return "btn-my-booking-widget "
  }
 const date = new Date(slot.date).toLocaleDateString()
 const onClick = (e) => {
 handleFlag(e, slot)
 } 

const btnLabel = () => {
return date + ": " + slot.bookingSlot + " - " + slot.bookingCourt
}

return (

<div>    
    <button className={btnClass()} onClick={onClick}>{btnLabel()}</button>
  </div>
  );
};

export default MyBookingWidget;
