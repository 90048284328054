const newBook = (flagsBook, flagsDelete, e, slot) => {
  const profile = JSON.parse(sessionStorage.getItem("profile"));
  const flagInfo = slot.name;
  const ownerId = flagInfo.split(",")[2];
  const myBooking = ownerId === profile._id;
  const flaggedBook = flagsBook.indexOf(flagInfo) >= 0;
  const flaggedDelete = flagsDelete.indexOf(flagInfo) >= 0;

  const handleRevertMine = () => {
    const flagIndex = flagsDelete.indexOf(flagInfo);
    flagsDelete.splice(flagIndex, 1);
    e.target.className = "btn-action btn-action-mine";
  };
  const handleFlagDelete = () => {
    flagsDelete.push(flagInfo);
    e.target.className = "btn-action btn-action-mine btn-flagged";
  };
  const handleRevertFree = () => {
    const flagIndex = flagsBook.indexOf(flagInfo);
    flagsBook.splice(flagIndex, 1);
    e.target.className = "btn-action btn-action-free";
  };
  const handleFlagBook = () => {
    flagsBook.push(flagInfo);
    e.target.className = "btn-action btn-action-free btn-flagged";
  };

  if (myBooking) {
    if (flaggedDelete) {
      handleRevertMine();
    } else {
      handleFlagDelete();
    }
  } else {
    if (flaggedBook) {
      handleRevertFree();
    } else {
      handleFlagBook();
    }
  }
};
export default newBook;
