const slotList = (bookings) => {
  //array to hold slots for a single court
  let thisCourt = [];
  //array to hold all courts data
  const result = [];

  const getName = (b) => {
    return b.bookingSlot._id + "," + b.bookingCourt._id;
  };
  const courts = JSON.parse(sessionStorage.getItem("courts"));
  const slots = JSON.parse(sessionStorage.getItem("slots"));

  //  Create array of all slots with booking info bookings
  if (bookings) {
    const bookedSlots = bookings.map(getName);
    courts.forEach((c) => {
      slots.forEach((s) => {
        const booked_index = bookedSlots.indexOf(s._id + "," + c._id);
        const booked = booked_index >= 0;
        const ownerId = booked ? bookings[booked_index].owner._id : null;
        const bookingId = booked ? bookings[booked_index]._id : null;
        const bookingOwner = booked
          ? bookings[booked_index].owner.name
          : "Available";
        thisCourt.push({
          court: c.value,
          time: s.label,
          booked: booked,
          name: s._id + "," + c._id + "," + ownerId + "," + bookingId,
          bookingOwner: bookingOwner,
          bookingId: bookingId,
        });
      });
      result.push(thisCourt);
      thisCourt = [];
    });
  }
  return result;
};
export default slotList;
