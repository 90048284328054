import TextFieldGroup from "../../Common/TextFieldGroup";
import { Button } from "rsuite";
import { changePw } from "../../../api";
import { useState } from "react";
import ModalHeader from "rsuite/esm/Modal/ModalHeader";
import ModalBody from "rsuite/esm/Modal/ModalBody";
import { useNavigate, useParams } from "react-router-dom";

const ChangePw = () => {
  const navigate = useNavigate();

  const initialState = { password: "", password2: "" };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  sessionStorage.setItem("token", JSON.stringify(useParams().token));

  const header = () => {
    return "Change password";
  };

  const formContent = () => {
    return (
      <div>
        <TextFieldGroup
          placeholder="password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          error={errors.password}
        />
        <TextFieldGroup
          placeholder="confirm password"
          name="password2"
          type="password"
          value={formData.password2}
          onChange={handleChange}
          error={errors.password2}
        />
      </div>
    );
  };

  const handleChangePw = async (e) => {
    e.preventDefault(); // prevent screen reload
    setLoading(true);
    try {
      sessionStorage.setItem(
        "profile",
        JSON.stringify((await changePw(formData)).data.result)
      );
    } catch (error) {
      console.log(error);
      setErrors(error);
    } finally {
      navigate("/");
      navigate(0);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <ModalHeader>{header()}</ModalHeader>
      <ModalBody>{formContent()}</ModalBody>
      <Button onClick={handleChangePw} loading={loading}>
        Change Password
      </Button>
    </div>
  );
};
export default ChangePw;
