import React from "react";
import bmc from "../../images/bmc.png";
import "./styles.css";

function Home() {

  return (
    <div className=" bg-none">
       <div className="container home">
        <img className="main-image fix-top" src={bmc} alt=""/>
      </div>
    </div>
  );
}

export default Home;
