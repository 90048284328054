import axios from "axios";

const token = JSON.parse(sessionStorage.getItem("token"));
const urlBase =
  process.env.NODE_ENV === "production"
    ? "https://mtc-backend-giyu.onrender.com"
    : "http://localHost:5000";
const API = axios.create({ baseURL: urlBase });

const auth = sessionStorage.getItem("profile");
const isLoggedIn = sessionStorage.getItem("courts");

API.interceptors.request.use((req, res) => {
  if (token) {
    req.headers.Authorization = `bearer ${token}`;
  }
  return req;
});

const fetchCourts = async () => {
  if (!isLoggedIn && auth) {
    const response = await API.get("/booking/courts");
    sessionStorage.setItem(
      "courts",
      JSON.stringify(await response.data.result)
    );
  }
};

const fetchSlots = async () => {
  if (!isLoggedIn && auth) {
    const response = await API.get("/booking/slots");
    sessionStorage.setItem("slots", JSON.stringify(await response.data.result));
  }
};
const fetchTypes = async () => {
  if (!isLoggedIn && auth) {
    const response = await API.get("/booking/types");
    sessionStorage.setItem("types", JSON.stringify(await response.data.result));
  }
};

export const dayBookings = (day) => {
  const response = API.get(`/booking/day/?date=${day}`);
  return response;
};
export const userBookings = (user) => {
  const response = API.get(`/booking/user/?user=${user}`);
  return response;
};
export const changePw = (formData) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  API.interceptors.request.use((req, res) => {
    if (token) {
      req.headers.Authorization = `bearer ${token}`;
    }
    return req;
  });
  const response = API.post("user/changePw/", formData);
  return response;
};

export const courts = await fetchCourts();
export const types = await fetchTypes();
export const slots = await fetchSlots();

export const updateSchedule = (submissions) =>
  API.post("/booking/", submissions);

export const expiredToken = () => API.post("user/checkToken", { token: token });
export const logIn = (formData) => API.post("user/login", formData);
export const register = (formData) => API.post("user/register", formData);
export const sendPwLink = (formData) => API.post("user/forgotPw", formData);
