import { useState, React } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "./Components/Calendar";
import Schedule from "./Components/Schedule";
import Recur from "./Components/Recur";
import MyBookings from "./Components/MyBookings";
import { Modal } from "rsuite";
import today from "../../utils/today";
import ExpiredToken from "../Auth/Modals/ExpiredToken";
import "../Booking/styles.css";
import submitDayBookings from "./utils/submitDayBookings";
import newBook from "./utils/newBook";

const Booking = (props) => {
  if (!sessionStorage.getItem("workingDate")) {
    sessionStorage.setItem("workingDate", today());
  }
  const [bookDate, setBookDate] = useState(
    sessionStorage.getItem("workingDate")
  );
  const [recurInt, setRecurInt] = useState(7);
  const [recurCount, setRecurCount] = useState(1);
  const [flagsBook, setFlagsBook] = useState([]);
  const [flagsDelete, setFlagsDelete] = useState([]);
  const [flagsExist, setFlagsExist] = useState(false);
  

  //maintain bookdate after submission

  const auth = JSON.parse(sessionStorage.getItem("profile"));
  const isAdmin = auth.class==="admin"
  const Navigate = useNavigate();

  const daysAhead = () => {
  return isAdmin?300:14
  }

  const dateChange = (e) => {
    sessionStorage.setItem("workingDate", e);
    setBookDate(e);
  };
  const recurIntChange = (e) => {
    setRecurInt(e);
  };
  const recurCountChange = (e) => {
    setRecurCount(e);
  };

  const updateBookings = (e, slot) => {
    newBook(flagsBook, flagsDelete, e, slot);
    setFlagsExist(flagsBook.length>0||flagsDelete.length>0)
  };


  const btnClass = () => {
  return  flagsExist?"btn-submit btn-active":"btn-submit btn-inactive"
  }
  const submitChanges = async () => {
    await submitDayBookings(
      bookDate,
      flagsBook,
      flagsDelete,
      recurInt,
      recurCount
    );
    Navigate(0);
  };

  const handleCanx = () => {
    Navigate("/");
  };

  const Unauthorised = () => {
    return (
      <div>
        <Modal open={true} onClose={handleCanx}>
          <Modal.Header>
            You need to be logged in to view this page
          </Modal.Header>
        </Modal>
      </div>
    );
  };

  if (!auth) {
    return Unauthorised();
  } else {
    const expiredToken = auth.expiresAt * 1000 <= Date.now();
    if (expiredToken) {
      return (
        <Modal open onClose={handleCanx}>
          <ExpiredToken />
        </Modal>
      );
    } else {
      switch (props.source) {
        case "schedule":
          return (
            <div>
              <div>
                <div className="calendar-container">
                  <Calendar
                    date={bookDate}
                    changeDate={dateChange}
                    daysAhead={daysAhead}
                    submitChanges={submitChanges}
                  />              
                <div hidden={!isAdmin}>
                <Recur
                  recurInt={recurInt}
                  recurCount={recurCount}
                  recurIntChange={recurIntChange}
                  recurCountChange={recurCountChange}
                ></Recur>
                </div>
                </div>
                <div className={"btn-submit-container"}>
                  <button className={btnClass()}  type="button" onClick={submitChanges}>
                    Submit Changes
                  </button>
                </div>
                <div className="schedule">
                  <Schedule
                    bookDate={bookDate}
                    recurInt={recurInt}
                    recurCount={recurCount}
                    updateBookings={updateBookings}
                    flagsBook={flagsBook}
                    flagsDelete={flagsDelete}
                  />
                </div>
              </div>
            </div>
          );
        case "user":
          return (
            <div>
              <div className="schedule">
                <MyBookings />
              </div>
            </div>
          );
        
        default:
          break;
      }
    }
  }
};
export default Booking;
