import logo from "../../images/bmc.png";
import "./styles.css";
export const NavBar = () => {
  const isAuthenticated =
    sessionStorage.getItem("profile") === null ? false : true;
  const userName = isAuthenticated
    ? JSON.parse(sessionStorage.getItem("profile")).name.replace(
        /"([^"]+)":/g,
        "$1:"
      )
    : "Guest";
  const userClass = isAuthenticated
    ? JSON.parse(sessionStorage.getItem("profile")).class
    : null;

  const greeting = "Welcome, " + userName;

  return (
    <nav className="bmc-nav">
      <a href="/" className="bmc-nav-item">
        {greeting}
      </a>
      <a href="/bookings" className="bmc-nav-item" hidden={isAuthenticated ? false : true}>
      Book a Court
      </a>
      <a href="/bookings/user" className="bmc-nav-item" hidden={isAuthenticated ? false : true}>
       My Bookings
      </a>
      <a className="bmc-nav-item log-reg" href="/login">
        {isAuthenticated ? "Log Out" : "Login/Register"}
      </a>
    </nav>
  );
};

export default NavBar;
